import React from 'react';
import styled from 'styled-components';
import KKZastalLogo from 'assets/logo-small.png';
import Container from './container';
import media from '../../utils/media';

const StyledHeader = styled.header`
  box-shadow: ${({ theme }) => theme.boxShadows.code};
`;

const StyledContainer = styled(Container)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;

  ${media.tablet} {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

const LogoContainer = styled.div`
  height: 100px;
  margin-right: 20px;
`;

const StyledLogo = styled.img`
  object-fit: cover;
  width: auto;
  height: 100%;
`;

const SloganContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  color: ${({ theme }) => theme.colors.green};

  span {
    font-weight: 600;
    margin-top: -10px;
    color: ${({ theme }) => theme.colors.orange};
  }

  ${media.tablet} {
    h1 {
      font-size: 3rem;
    }

    span {
      font-size: 0.75rem;
    }
  }
`;

const Header = () => (
  <StyledHeader>
    <StyledContainer>
      <LogoContainer>
        <StyledLogo src={KKZastalLogo} />
      </LogoContainer>
      <SloganContainer>
        <h1>#GramyRazem</h1>
        <span>Kibice płacą mniej!</span>
      </SloganContainer>
    </StyledContainer>
  </StyledHeader>
);

export default Header;
