import React from 'react';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle } from 'style/GlobalStyle';
import theme from 'style/theme';
import Header from './header';
import Footer from './footer';

const Layout = ({ children }) => (
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    <>
      <Header />
      <main>{children}</main>
      <Footer />
    </>
  </ThemeProvider>
);

export default Layout;
