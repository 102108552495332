import React from 'react';
import styled from 'styled-components';
import Container from './container';

const StyledFooter = styled.footer`
  background: ${({ theme }) => theme.colors.green};
  padding: 20px 0;
  color: ${({ theme }) => theme.colors.white};
`;

const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-between;

  a {
    font-weight: 700;
    color: ${({ theme }) => theme.colors.black};

    :hover {
      text-decoration: underline;
    }
  }
`;

const Footer = () => (
  <StyledFooter>
    <StyledContainer>
      <div>{new Date().getFullYear()} © KK Zastal</div>
      <div>
        Code by{' '}
        <a href="https://github.com/norbertsuski" target="_blank" rel="noreferrer">
          Norbert Suski
        </a>
      </div>
    </StyledContainer>
  </StyledFooter>
);

export default Footer;
