import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const SEO = ({
  description,
  lang,
  meta,
  title,
  url,
  image,
  type,
  keywords,
  articlePublishedTime,
  articleSection,
  articleTags,
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
            siteUrl
            keywords
            fbAppId
          }
        }
      }
    `
  );

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title || site.siteMetadata.title}
      titleTemplate={title ? `%s | ${site.siteMetadata.title}` : `${site.siteMetadata.title}`}
      meta={[
        {
          name: 'keywords',
          content: keywords || site.siteMetadata.keywords,
        },
        {
          name: 'description',
          content: description || site.siteMetadata.description,
        },
        {
          property: 'og:title',
          content: title ? `${title} | ${site.siteMetadata.title}` : site.siteMetadata.title,
        },
        {
          property: 'og:description',
          content: description || site.siteMetadata.description,
        },
        {
          property: 'og:site_name',
          content: site.siteMetadata.title,
        },
        {
          property: 'og:type',
          content: type || 'website',
        },
        {
          property: 'og:image',
          content: image && image.startsWith('http') ? image : `${site.siteMetadata.siteUrl}${image}`,
        },
        {
          property: 'og:url',
          content: url ? `${site.siteMetadata.siteUrl}/${url}` : site.siteMetadata.siteUrl,
        },
        {
          property: 'fb:app_id',
          content: site.siteMetadata.fbAppId,
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: site.siteMetadata.author,
        },
        {
          name: 'twitter:title',
          content: title || site.siteMetadata.title,
        },
        {
          name: 'twitter:description',
          content: description || site.siteMetadata.description,
        },
        {
          name: 'article:published_time',
          content: articlePublishedTime,
        },
        {
          name: 'article:section',
          content: articleSection,
        },
        {
          name: 'article:tag',
          content: articleTags,
        },
      ].concat(meta)}
    >
      <link
        href="https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,400;0,700;0,900;1,400;1,700;1,900&family=Montserrat&display=swap"
        rel="stylesheet"
      />
    </Helmet>
  );
};

SEO.defaultProps = {
  lang: 'pl',
  meta: [],
  description: '',
  title: undefined,
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
};

export default SEO;
