export default {
  settings: {
    sectionWidth: '900px',
  },
  colors: {
    white: '#FFFFFF',
    brown: '#A65526',
    orange: '#F26E22',
    green: '#03A64A',
    black: '#262223',
  },
  breakpoints: {
    width: {
      mobile: '480px',
      tablet: '768px',
      desktop: '1024px',
      largeDesktop: '1920px',
    },
  },
  boxShadows: {
    base: '0px 10px 20px 0px rgba(0,0,0,0.1)',
    baseTop: '0px 0px 20px 0px rgba(0,0,0,0.1)',
    code: '0px 5px 10px 0px rgba(0,0,0,0.1)',
  },
};
