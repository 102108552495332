import React, { memo } from 'react';
import styled from 'styled-components';
import { string, node } from 'prop-types';

const Container = styled.div`
  max-width: ${({ theme }) => theme.settings.sectionWidth};
  margin: 0 auto;
  padding: 0 20px;
`;

const StyledContainer = memo(({ children, className }) => <Container className={className}>{children}</Container>);

StyledContainer.propTypes = {
  className: string,
  children: node.isRequired,
};

export default StyledContainer;
