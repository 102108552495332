import React from 'react';
import { string, bool } from 'prop-types';
import { Link } from 'gatsby';
import styled from 'styled-components';

const StyledButton = styled(Link)`
  padding: 16px 32px;
  border-radius: 24px;
  font-weight: 500;
  background: ${({ theme, light }) => (light ? theme.colors.ultraviolet : theme.colors.blueDark)};
  color: ${({ theme }) => theme.colors.white};
`;

const CTAButton = ({ to, text, light }) => (
  <StyledButton light={light} to={to}>
    {text}
  </StyledButton>
);

CTAButton.propTypes = {
  to: string.isRequired,
  text: string,
  light: bool,
};

CTAButton.defaultProps = {
  text: 'Estimate your project',
  light: false,
};

export default CTAButton;
